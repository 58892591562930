import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { creditStore } from '@/store/credits/state';
import { store } from '@/store/listing/state';
import { templates } from '@/store/projects/state';
import { useStream } from '@/store/useStream';

import { dataStates } from '@/utils/constants';

import { SwwcButton } from '@/swwc';

/**
 * Button to create a new job of the given type
 * @typedef {Object} ButtonProps
 * @property {string} key - the jobType key for translation text
 * @property {string} id - the jobType ID
 * @property {string} dataCy - Attribute to facilitate targetting in tests
 *
 * @param {ButtonProps} props
 * @returns {React.ReactElement}
 */
const Button = ({ key, id, dataCy }) => {
   const { dataState } = creditStore.value;
   const navigate = useNavigate();
   const { t } = useTranslation();
   const jobType = t(`jobTypeName.${key}`);

   return (
      <SwwcButton
         key={jobType}
         type="button"
         label={jobType}
         variant="accent-high-contrast"
         icon="cmdAdd13"
         onClick={() => {
            navigate(id);
            store.actions.resetCurrentPage();
         }}
         data-cy={dataCy}
         disabled={dataState === dataStates.error || dataState === dataStates.migrating}
      />
   );
};

export const AppsButtons = () => {
   const apps = useStream(templates.state);

   return apps.map(Button);
};
