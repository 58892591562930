// @ts-nocheck
import logoUrl from '/img/simcenter-x-logo.png';
import auth from '@simcenter-x/store/auth';
import { compose, prop, propEq, when } from 'ramda';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useClient } from 'urql';

import { makeEndpoints } from '@/store/common/urls';
import { creditActions } from '@/store/credits/actions';
import { featureFlags } from '@/store/featureFlags/store';
import { jobTypesStore } from '@/store/jobtypes/store';
import state, { store } from '@/store/listing/state';
import { store as settingsStore } from '@/store/settings/store';
import { useStream } from '@/store/useStream';

import { UserNavigationPanel } from '@/modules/common/SidebarPanels';
import { ToastHandler } from '@/modules/common/ToastHandler';
import { Access } from '@/modules/common/access/Access';
import { ActionsJobModal } from '@/modules/listing/components/ActionsJobModal';

import { dataStates, errorTypes, productBrand } from '@/utils/constants';
import { addEmbeddedMessageHandler, isEmbedded, sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { rejectByType } from '@/utils/filters';

import { SwwcApplicationFrame, SwwcCommandButton, SwwcPrimaryNavigationBar, SwwcPrimaryNavigationPanel } from '@/swwc';

import { AuthContext } from '@/AuthContext';
import { initAnalytics } from '@/analytics/init';
import { initRefiner } from '@/analytics/refiner';
import { initWalkme, openWalkme } from '@/walkme';

export const Layout = () => {
   const navigate = useNavigate();
   const client = useClient();
   const { errors } = useStream(state.app.state);
   const errorsFiltered = rejectByType(errorTypes.jobGetError)(errors);
   const { userEntitlementData } = useStream(settingsStore.state);

   useEffect(() => {
      if (auth.isLoggedIn.value) {
         makeEndpoints(new URLSearchParams(window.location.search), auth.state.value);
         featureFlags.load(client);
         store.actions.getWarnInfo();
         store.actions.loadJobs(client, true);
         jobTypesStore.actions.getSystemJobTemplates(client);
         creditActions.loadCredits(client, true);
         settingsStore.actions.getEntitlement(client);
         settingsStore.actions.initFromStorage(localStorage);
         settingsStore.actions.setWMCBranch(window.location.search);
         initWalkme(auth.state.value);
      }
   }, [auth.isLoggedIn.value]);

   useEffect(() => {
      if (userEntitlementData.dataState === dataStates.ready) {
         initAnalytics();
         initRefiner();
      }
   }, [userEntitlementData.dataState]);

   // embedded handler for redirect to new job
   useEffect(() => {
      if (isEmbedded) {
         addEmbeddedMessageHandler(
            'redirectTo',
            when(
               prop('detail'),
               compose(
                  when(propEq('newjob', 'path'), () => {
                     navigate('/hpcJob');
                     sendEmbeddedMessage('populateSimFileField', { cssSelector: "input[name='simulationFile']" });
                  }),
                  JSON.parse,
                  prop('detail'),
               ),
            ),
         );
      }
   }, []);

   useEffect(() => {
      const shadowRoot = document.querySelector('#simx-nav-user')?.shadowRoot;
      if (shadowRoot) {
         const sheet = new CSSStyleSheet();
         sheet.replaceSync(`
            :host(.vertical) .swwc-command-button--button {
               min-height: 4rem !important;
            }
         `);
         shadowRoot.adoptedStyleSheets = [...shadowRoot.adoptedStyleSheets, sheet];
      }
   }, []);

   return (
      <AuthContext>
         <SwwcApplicationFrame app-name={productBrand}>
            <SwwcPrimaryNavigationBar slot="primary-navigation" hideCommandItemsLabel hideAccountIcon>
               <img src={logoUrl} slot="edge-icon-top" width="32" height="32" alt="Simcenter X" />
               <SwwcCommandButton
                  slot="top-section--command-items"
                  onClick={() => navigate('/')}
                  id="simx-nav-home"
                  icon="cmdHome24"
                  iconSize="lg"
                  orientation="vertical"
               />
               <div slot="bottom-section--command-items" className="w-full">
                  <SwwcCommandButton
                     icon="cmdSettings24"
                     onClick={() => navigate('/settings')}
                     id="simx-nav-settings"
                     iconSize="lg"
                     orientation="vertical"
                  />
                  <SwwcCommandButton
                     icon="cmdHelp24"
                     onClick={openWalkme}
                     id="simx-nav-help"
                     iconSize="lg"
                     orientation="vertical"
                  />
               </div>
               <div slot="bottom-section--command-items" id="user-logout-avatar" className="w-full mb-[8px]">
                  <SwwcCommandButton icon="cmdUser24" iconSize="lg" id="simx-nav-user" orientation="vertical" />
                  <div className="rounded bg-white" />
               </div>
               <SwwcPrimaryNavigationPanel
                  slot="navigation-panel"
                  panelTitle={null}
                  triggerCommandButtonToggleId="simx-nav-user"
                  closeOnClickOutside
                  alignBottom
                  style={{ '--swwc-primary-navigation-panel--width': '280px' }}
               >
                  <UserNavigationPanel />
               </SwwcPrimaryNavigationPanel>
            </SwwcPrimaryNavigationBar>
            <main slot="content" className="relative overflow-x-hidden h-full">
               <Access />
               <ToastHandler item={errorsFiltered[0]} />
               <ActionsJobModal />
               <Outlet />
            </main>
         </SwwcApplicationFrame>
      </AuthContext>
   );
};
